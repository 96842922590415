import * as storageConstants from '../Constants/storageConstant';
import { IMAGE_BASE_URL } from '../Constants/urlConstants';
import { changeDateFormatForDisplay } from '../Utils/dateUtils';
import { isNullOrEmpty } from '../Utils/stringUtils';
import { warn } from '../Wrapper/toast/toast';
import * as apiHelper from "./ApiHelper";
import numberToWords from 'number-to-words';
import { formatValue } from 'react-currency-input-field';

const localStorageArray = [storageConstants.AUTH, storageConstants.PROFILE, storageConstants.USER_ROLE, storageConstants.BUSINESS, storageConstants.BUSINESS_LIST, storageConstants.MASTER_DATA, storageConstants.SYSTEM_DATA, storageConstants.NAME];
const sessionStorageArray = [];

export function getLocalData(name) {
    try {
        if (sessionStorageArray.includes(name)) {
            return (name === storageConstants.AUTH || name === storageConstants.USER_ROLE) ? sessionStorage.getItem(name) : JSON.parse(sessionStorage.getItem(name));
        }
        else if (localStorageArray.includes(name)) {
            return (name === storageConstants.AUTH || name === storageConstants.USER_ROLE) ? localStorage.getItem(name) : JSON.parse(localStorage.getItem(name));
        }
        return null;
    } catch (e) {
        return null;
    }
}

export function storeLocalData(name, data) {
    try {
        if (sessionStorageArray.includes(name)) {
            (name === storageConstants.AUTH || name === storageConstants.USER_ROLE) ? sessionStorage.setItem(name, data) : sessionStorage.setItem(name, JSON.stringify(data, (k, v) => v === undefined ? null : v));
        }
        else if (localStorageArray.includes(name)) {
            (name === storageConstants.AUTH || name === storageConstants.USER_ROLE) ? localStorage.setItem(name, data) : localStorage.setItem(name, JSON.stringify(data, (k, v) => v === undefined ? null : v));
        }
        return true;
    } catch (e) {
        alert('Your web browser does not support storing settings locally. Some settings may not save or some features may not work properly for you.');
        return false;
    }
}

export function clearLocalStorageData() {
    try {
        localStorage.clear();
        return true;
    } catch (e) {
        return false;
    }
}

export const getMasterDataFromLocal = () => {
    return getLocalData(storageConstants.MASTER_DATA);
}

export const getSystemDataFromLocal = async () => {
    return getLocalData(storageConstants.SYSTEM_DATA);
}

export const fetchMasterData = (setLoading) => {
    setLoading && setLoading(true);
    apiHelper.masterData().then(response => {
        setLoading && setLoading(false);
        if (response?.isSuccess) {
            storeLocalData(storageConstants.MASTER_DATA, response?.data?.data);
        }
    });
}

export function isEmailValid(email) {
    const regex = /^(([A-Za-z0-9](?!.*\.{2})[A-Za-z0-9_\-\.]+[A-Za-z0-9])|([A-Za-z0-9]{1,60}))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,15}|[0-9]{1,3})(\]?)$/; /* eslint-disable-line */
    return regex.test(email);
}

export const preventPlusMinusInNumberInput = (e) => {
    // Prevent entry of minus sign (keycode 189) and plus sign (keycode 187)
    if (e.keyCode === 189 || e.keyCode === 109 || e.keyCode === 187 || e.keyCode === 107) {
        e.preventDefault();
    }
}

const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
};

export const numberToWordsStringUSD = (number) => {
    if (number == null) return '';

    const [integerPart, decimalPart] = number.toString().split(".");
    const integerInWords = numberToWords.toWords(integerPart);
    const decimalInWords = decimalPart ? numberToWords.toWords(decimalPart) : null;
    const result = decimalInWords ? `${integerInWords} point ${decimalInWords}` : integerInWords;

    return capitalizeWords(result)
};

const units = [
    { value: 10000000, name: 'crore' },
    { value: 100000, name: 'lakh' },
    { value: 1000, name: 'thousand' },
    { value: 100, name: 'hundred' },
];

export const numberToWordsStringINR = (number) => {
    if (number == null || isNaN(number)) return '';

    const [integerPart, decimalPart] = number.toString().split(".");
    const integerValue = parseInt(integerPart, 10);

    // Handle integer part
    let remainder = integerValue;
    let resultArray = [];

    for (const unit of units) {
        if (remainder >= unit.value) {
            const quotient = Math.floor(remainder / unit.value);
            remainder = remainder % unit.value;
            resultArray.push(`${numberToWords.toWords(quotient)} ${unit.name}`);
        }
    }

    if (remainder > 0) {
        resultArray.push(numberToWords.toWords(remainder));
    }

    // Join result array for integer part
    let result = resultArray.join(' ');

    // Handle decimal part
    if (decimalPart) {
        const decimalInWords = numberToWords.toWords(decimalPart);
        result = `${result} point ${decimalInWords}`;
    }

    return capitalizeWords(result);
};


export function checkUrl(string) { // eslint-disable-next-line
    return /^(?:(https?|ftp):\/\/)?[^\s\/$?#].[^\s]*\.[a-zA-Z]{2,}.*$/i
        .test(string.includes("www.") ? string.replace("www.", "") : string);
}

export const formattedValue = (val, prefix, currency = 'INR', allowDecimals = true) => formatValue({
    value: `${val}`,
    groupSeparator: ',',
    decimalSeparator: '.',
    prefix: prefix ? (currency === 'INR' ? "₹" : "$") : "",
    intlConfig: prefix ? (currency === 'INR' ? { locale: 'en-IN', currency: 'INR' } : { locale: 'en-US', currency: 'USD' }) : null,
    decimalScale: (prefix || allowDecimals) ? 2 : null,
});

export const getBusinessList = async (query, setBusinessList, setLoading,) => {
    setLoading && setLoading(true);

    let businessList;
    await apiHelper.getBusinessList(query).then(response => {
        setLoading && setLoading(false);
        if (response?.isSuccess) {
            storeLocalData(storageConstants.BUSINESS_LIST, response?.data?.data);
            setBusinessList(response?.data);
            businessList = response?.data;
        } else {
            warn(response?.message);
        }
    });

    return businessList;
}

export const getCountryOptions = () => {
    const data = getLocalData(storageConstants.MASTER_DATA);

    if (data) {
        return [...data?.countries?.map(c => ({
            label: c.value,
            value: c.key,
        }))];
    } else {
        fetchMasterData();
    }
}

export const getStateOptions = () => {
    const data = getLocalData(storageConstants.MASTER_DATA);

    if (data) {
        return [...data?.states?.map(s => ({
            label: s.value,
            value: s.key,
        }))];
    } else {
        fetchMasterData();
    }
}

export const getStateNameFromKey = (key = '') => {
    const data = getStateOptions();

    if (key && data) {
        return [...data?.filter(s => s.value === key)][0]?.label;
    } else return '';
}

export const getCurrencyOptions = () => {
    const data = getLocalData(storageConstants.MASTER_DATA);

    if (data) {
        return [...data?.currencies?.map(c => ({
            label: `${c.value} ${c.symbol}`,
            value: c.key,
        }))];
    } else {
        fetchMasterData();
    }
}

export const getIndustryOptions = () => {
    const data = getLocalData(storageConstants.MASTER_DATA);

    if (data) {
        return [...data?.industries?.map(i => ({
            label: i.value,
            value: i.key,
        }))];
    } else {
        fetchMasterData();
    }
}

export const getTaxTreatmentOptions = () => {
    const data = getLocalData(storageConstants.MASTER_DATA);

    if (data) {
        return [...data?.txt_treatment?.map(t => ({
            label: t.value,
            value: t.key,
        }))];
    } else {
        fetchMasterData();
    }
}

export const getPaymentStatusOptions = () => {
    const data = getLocalData(storageConstants.MASTER_DATA);

    if (data) {
        return [...data?.status?.map(s => ({
            label: s.value,
            value: s.key,
        }))];
    } else {
        fetchMasterData();
    }
}

export const getPaymentStatusName = (key) => {
    const data = getPaymentStatusOptions();

    if (data) {
        return [...data?.filter(s => s.value === key)][0]?.label;
    }
}

export const getBusinessDetailsFromList = (id, businessList) => {
    if (id && businessList) {
        return [...businessList?.filter(data => data.value === parseInt(id))]
    } else {
        return []
    }
}

export const getBusinessName = (id) => {
    const businessList = getLocalData(storageConstants.BUSINESS_LIST);

    if (businessList?.length > 0 && id) {
        return businessList?.filter(data => data.id === parseInt(id))[0]?.name;
    } else {
        return '';
    }
}

export const getBusinessListOptionsFromLocal = () => {
    const businessList = getLocalData(storageConstants.BUSINESS_LIST);

    if (businessList?.length > 0) {
        return [...businessList?.map(data => ({ label: data.name, value: data.id }))];
    } else {
        return [];
    }
}

const selectedBank = (id, list) => list?.filter(b => parseInt(b?.value) === parseInt(id))[0] ?? {};
const selectedClient = (id, list) => list?.find(c => parseInt(c?.value) === parseInt(id));

const totalIGST = (data, needSGST) => data?.reduce((total, data) => {
    const rate = parseFloat(data?.rate) || 0;
    const quantity = parseInt(data?.quantity) || 0;
    const amount = rate * quantity;
    const gst = parseInt(data?.gst || 0);
    const igstForItem = (amount * (needSGST ? (gst * 0.5) : (gst * 1))) / 100;

    return total + igstForItem;
}, 0);

const totalAmount = data => data?.reduce((acc, data) => {
    const rate = parseFloat(data?.rate) || 0;
    const quantity = parseInt(data?.quantity) || 0;
    const amount = rate * quantity;
    return acc + amount;
}, 0);

const calculatedDiscount = (inputValues, needSGST, totalAmount, totalIGST) => ((!isNullOrEmpty(inputValues?.discount) || !isNullOrEmpty(inputValues?.discountType)) ? (inputValues?.discountType === 'FLAT' ? inputValues?.discount : inputValues?.discountType === 'PERCENTAGE' ? ((totalAmount + (needSGST ? totalIGST * 2 : totalIGST)) * inputValues?.discount) / 100 : 0) : 0);

const totalAmountPaid = invoice_payment => invoice_payment?.reduce((acc, curr) => acc += curr?.amt_settle, 0);

const finalAmount = (totalAmount, totalIGST, calculatedDiscount, totalAmountPaid, needSGST) => totalAmount + (needSGST ? (2 * (totalIGST ?? 0)) : totalIGST ?? 0) - calculatedDiscount - totalAmountPaid;

export const getPDFDataFromResponseData = (d, type, bankList, clientList, business) => {
    const c = d?.client;
    clientList.push({
        label: c.name,
        value: c.id,
        state: c?.c_state ?? '',
        address: c?.c_address ?? '',
        zip: c?.c_zip_code ?? '',
        gst: c?.business_gst ?? '',
        pan: c?.business_pan_no ?? '',
        email: c?.email ?? '',
        phone: c?.mno ?? '',
        showEmail: c?.email_same_invoice === '1',
        showPhone: c?.mno_same_invoice === '1',
    });

    const rows = (Array.isArray(d?.custom_data) && d?.custom_data?.length)
        ? d?.custom_data?.map(row => ({ label: row.name, value: row.value }))
        : [];

    let needSGST = false;
    let itemsFieldName = type === 'p'
        ? 'proforma_invoice_item'
        : type === 'i'
            ? 'invoice_item'
            : '';

    const tableData = d[itemsFieldName]?.length
        ? d[itemsFieldName]?.map(data => {
            return {
                id: data.id,
                item: data.item,
                quantity: data.quantity,
                rate: data.rate,
                gst: data.gst ?? 0,
                amount: data.amount,
            };
        })
        : [];

    let inputValuesBankDetails = {};
    if (!isNullOrEmpty(d?.bank_id) && !isNullOrEmpty(selectedBank(d?.bank_id, bankList))) {
        const { label, holder_name, address, account_no, ifsc_code } = selectedBank(d?.bank_id, bankList);
        inputValuesBankDetails = { id: d?.bank_id, account_no, ifsc_code, address, name: label, holder_name };
    }

    let inputValues = {
        invoiceId: d?.id, text: d?.title, status: getPaymentStatusName(d?.status), logo: IMAGE_BASE_URL + (d?.invoice_logo ?? business.logo), convertLogoImage: false, invoiceDate: changeDateFormatForDisplay(d?.invoice_date), dueDate: changeDateFormatForDisplay(d?.invoice_due_date), invoiceNo: d?.invoice_number ?? '', rows, billedBySelect: d?.business_id, billedToSelect: d?.client_id, billedToCurrency: d?.currency ?? 'INR', notes: d?.remark ?? '', discountType: d?.discount_type ?? '', discount: d?.discount, totalAmount: d?.total_amount, roundOff: d?.roundOff ?? 0, bankDetails: inputValuesBankDetails, bank_info: !isNullOrEmpty(d?.bank_id), invoice_payment: d?.invoice_payment || [],
    }

    const businessListFromLocal = getLocalData(storageConstants.BUSINESS_LIST);

    if (!isNullOrEmpty(inputValues.billedBySelect) && businessListFromLocal?.length > 0) {
        const selectedBusinessList = businessListFromLocal?.filter(data => parseInt(data?.id) === parseInt(d?.business_id));

        inputValues.billedByBusinessName = selectedBusinessList[0]?.name;
        inputValues.billedByState = selectedBusinessList[0]?.state;
        inputValues.billedByPAN = selectedBusinessList[0]?.pan_no;
        inputValues.billedByGst = selectedBusinessList[0]?.gst_no;
        inputValues.billedByAddress = selectedBusinessList[0]?.address;
    }

    const client = selectedClient(inputValues.billedToSelect, clientList);

    if (client) {
        needSGST = client?.state?.toLowerCase() === inputValues?.billedByState?.toLowerCase();

        inputValues.billedToBusinessName = client?.label;
        inputValues.billedToAddress = client?.address;
        inputValues.clientGSTAndPAN = { gst: client?.gst, pan: client?.pan };
        inputValues.showClientEmailAndPhone = {
            showEmail: client?.showEmail,
            showPhone: client?.showPhone,
            email: client?.email,
            mno: client?.mno,
        };
    }

    const totalIGSTNew = totalIGST(tableData, needSGST);
    const totalAmountNew = totalAmount(tableData);
    const calculatedDiscountNew = calculatedDiscount(inputValues, needSGST, totalAmountNew, totalIGSTNew);
    const totalAmountPaidNew = totalAmountPaid(inputValues?.invoice_payment);
    const finalAmountNew = finalAmount(totalAmountNew, totalIGSTNew, calculatedDiscountNew, totalAmountPaidNew, needSGST);

    return {
        inputValues,
        tableData,
        totalIGST: totalIGSTNew,
        totalAmount: totalAmountNew,
        calculatedDiscount: calculatedDiscountNew,
        totalAmountPaid: totalAmountPaidNew,
        finalAmount: finalAmountNew,
        needSGST,
    };
}
