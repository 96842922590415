// response.js
import * as commonServices from './common';
import { SUCCESS_CODE, UNAUTHORIZED_ERROR_CODE, RESOURCE_CREATED, BAD_REQUEST_CODE, EMAIL_UNVERIFIED } from '../Constants/responseCodes';
import { trackAPITime } from '../Helpers/analytics';

export function handleResponse(response, startTime, url) {

    const endTime = Date.now(); // Capture the end time
    const apiTime = endTime - startTime; // Calculate API time
    trackAPITime(apiTime, url, SUCCESS_CODE);

    if (response?.data?.code === SUCCESS_CODE || response?.data === RESOURCE_CREATED) {
        response.data.isSuccess = true;
    } else if (response?.data?.code === UNAUTHORIZED_ERROR_CODE) {
        commonServices.clearLocalStorageData();
        window.location.replace('/');
        return;
    }
    else {
        response.data.isSuccess = false;
    }
    return response.data;
}

export function handleError(error, startTime, url) {

    const endTime = Date.now(); // Capture the end time
    const apiTime = endTime - startTime; // Calculate API time
    trackAPITime(apiTime, url, "ERROR");

    if (error?.response?.data?.code === BAD_REQUEST_CODE || error?.response?.data?.code === EMAIL_UNVERIFIED) {
        error.response.data.isSuccess = false;
        return error.response.data;
    }
    else if (error?.response?.status === UNAUTHORIZED_ERROR_CODE) {
        commonServices.clearLocalStorageData();
        window.location.replace('/');
        return;
    }
}